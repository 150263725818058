import EcoLoss from "../image/product/eco-loss.png"
import CaseBuilder from "../image/product/case-builder.png"
import DamagesCalculator from "../image/product/damages-calculator.png"

export const productsList = [
  {
    name: "Economic Loss Analyser",
    productImage: "",
    description:
      "Powerful Economic Loss Analysis with a Paint-By-Numbers User Interface.",
    link: "/products/eco-loss-analyser",
    image: EcoLoss,
  },
  {
    name: "Damages Calculator",
    productImage: "",
    link: "/products/damages-calculator",
    description: "Sophisticated Damages Calculations with No Math Required.",
    image: DamagesCalculator,
  },
]

export const nextProductsList = [
  {
    name: "Case Builder",
    productImage: "",
    description:
      "The Facts at you Fingertips. <br/> Next Gen Automation, Coordination and Innovation to Multiply Your Team and Scale Your Practice.",
    link: "/products/case-builder",
    image: CaseBuilder,
  },
]
