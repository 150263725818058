import { Link, GatsbyLinkProps } from "gatsby"
import React, { ReactElement } from "react"

export default function Button({
  invert = false,
  className = "",
  ...rest
}: { invert?: boolean } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>): ReactElement {
  return (
    <button
      className={`
      shadow-md
      font-bold
      focus:outline-none focus:ring-2 focus:ring-br-primary-blue focus:ring-offset-2
      ${
        invert
          ? `text-br-primary-blue bg-white px-4 rounded-full py-2 border border-br-primary-blue`
          : "bg-br-primary-blue text-white px-4 rounded-full py-2 hover:text-white hover:bg-opacity-75"
      } ${className}`}
      {...rest}
    ></button>
  )
}

export function ButtonLink({
  invert = false,
  className = "",
  to = "",
  external = false,
  children,
}: {
  invert?: boolean
  className?: string
  external?: boolean
  to: string
  children?: React.ReactNode
}): ReactElement {
  return (
    <Link
      {...(external ? { target: "_blank", rel: "noopener noreferrer" } : {})}
      className={`
      shadow-md
      font-bold
      !no-underline
      focus:outline-none focus:ring-2 focus:ring-br-primary-blue focus:ring-offset-2
      ${
        invert
          ? `text-br-primary-blue bg-white px-4 rounded-full py-2 border border-br-primary-blue`
          : "bg-br-primary-blue text-white px-4 rounded-full py-2 hover:text-white hover:bg-opacity-75"
      } ${className}`}
      to={to}
    >
      {children}
    </Link>
  )
}
