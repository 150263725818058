import { Switch, Tooltip } from "@blueprintjs/core"
import React, { ReactElement } from "react"
import { DesktopNav } from "./DesktopNav"
// import law-lite.png

interface Props {}

export default function Navbar({}: Props): ReactElement {
  return <DesktopNav />
}
