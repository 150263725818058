import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import LawliteLogo from "../../image/logo/law-lite.png"
import Button from "../Button/Button"
import Hamburger from "../../assets/hamburger.svg"
import { globalHistory } from "@reach/router"
import { nextProductsList, productsList } from "../../constants/products"
import { ArrowRightIcon } from "@heroicons/react/solid"

export interface ParentProps {
  children?: React.ReactNode
  className?: string
}
const NavItem = ({
  children,
  className = "",
  ...rest
}: ParentProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={`py-3 lg:py-4 px-4  ${className}`} {...rest}>
      {children}
    </div>
  )
}

const NavLink = ({
  children,
  className = "",
  to,
  ...rest
}: ParentProps & { to: string } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <NavItem className={className} {...rest}>
      <div className="text-br-primary-blue font-bold text-lg font-roboto">
        <Link to={to} className="!no-underline">
          {children}
        </Link>
      </div>
    </NavItem>
  )
}
export const DesktopNav = () => {
  const [popup, setShowPopup] = useState(false)
  const [show, setShow] = useState(false)
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setShow(false)
      }
    })
  }, [])
  return (
    <>
      <div className="fixed border-b shadow-sm w-full flex items-center top-0 z-50 bg-white">
        <NavLink to="/" className="mr-10 lg:ml-5">
          <div className="h-7 lg:h-10">
            <img
              src={LawliteLogo}
              className="h-full object-contain"
              alt="Law-Lite"
            />
          </div>
        </NavLink>
        <div
          className={`absolute max-h-[80vh] overflow-y-auto lg:overflow-y-visible shadow-md lg:shadow-none lg:static w-full bg-white top-[100%] ${
            show ? "flex" : "hidden"
          } flex-col items-center flex-grow lg:flex-row  lg:flex`}
        >
          <NavLink
            to="/#products"
            className="relative"
            onMouseEnter={() => {
              setShowPopup(true)
            }}
            onMouseLeave={() => {
              setShowPopup(false)
            }}
          >
            Products
            {popup && (
              <div className="lg:flex absolute hidden z-10">
                <div className="bg-white w-[500px] shadow-lg my-5 rounded-lg border py-4 px-4">
                  {productsList.concat(nextProductsList).map((x, i) => {
                    return (
                      <Link className="no-underline" to={x.link} key={i}>
                        <div className="flex py-5 hover:bg-br-primary-blue hover:bg-opacity-10 group">
                          <div className="h-20 overflow-hidden mr-2 flex-shrink-0">
                            <img
                              src={x.image}
                              alt={x.name}
                              className="h-full object-contain"
                            />
                          </div>
                          <div className="flex flex-col justify-between">
                            <div>{x.name}</div>
                            <div className="text-sm text-gray-500 font-normal">
                              {x.description.split("<br/>")[1] || x.description}
                            </div>
                          </div>
                          <div className="text-br-primary-blue  items-center  mx-6 flex">
                            <ArrowRightIcon className="w-4 group-hover:opacity-100 opacity-0 transition-all duration-500 -translate-x-4 group-hover:-translate-x-0 transform" />
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            )}
          </NavLink>
          {productsList.concat(nextProductsList).map(product => (
            <NavLink to={product.link} className="lg:hidden" key={product.name}>
              {product.name}
            </NavLink>
          ))}
          <NavLink to="/tutorials/">Tutorials</NavLink>
          <NavLink to="/#updates">Updates</NavLink>
          <NavLink to="/frequently-asked-questions/">FAQ</NavLink>

          <NavLink to="/book-a-demo" className="lg:!py-0 lg:ml-auto">
            <Button>Book a Demo</Button>
          </NavLink>
          <NavLink to="/sign-in" className="lg:!py-0 lg:pr-10 lg:pl-0">
            <Button invert className="!px-6">
              Login
            </Button>
          </NavLink>
        </div>
        <Hamburger
          className="cursor-pointer lg:h-8 h-6 max-h-8 mr-4 lg:hidden ml-auto fill-current text-br-primary-blue"
          onClick={() => setShow(!show)}
        />
      </div>
      <div className="h-20"></div>
    </>
  )
}
