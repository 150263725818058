export const amplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_5C5NicyEM",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "3o3eloplamqi6d8frmktvt06cp",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH",
  },

  Analytics: {
    disabled: true,
  },
}
