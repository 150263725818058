import { Link } from "gatsby"
import React, { ReactElement } from "react"
import Youtube from "../../image/logo/youtube-small.png"
import LinkedInLog from "../../image/logo/linkedin.png"

const footerLinks = [
  {
    name: `© ${new Date().getFullYear()} LawLite Pty Ltd. All rights reserved.`,
  },
  {
    name: "Privacy Policy",
    link: "/privacy/",
  },
  {
    name: "Terms & Conditions",
    link: "/terms/",
  },
]
export default function Footer(): ReactElement {
  return (
    <div className="flex flex-col items-center w-full  border-t pb-20 lg:pb-5  py-5 lg:pl-10 lg:pr-12">
      <div className="flex flex-col items-center lg:justify-center lg:flex-row w-full mt-4">
        <FooterItem name={"FAQ"} link={"/frequently-asked-questions"} />
        <FooterItem name={"About Us"} link={"/about"} />
        <div className="flex  mt-4 lg:mt-0 items-center">
          <a
            href="https://www.youtube.com/channel/UCpMNFMV4s94YAYvR1G1AFdw/playlists"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="h-7 lg:h-10 mx-2 object-cover"
              src={Youtube}
              alt={"Youtube"}
            />{" "}
          </a>
          <a
            href="https://www.linkedin.com/company/lawlite/"
            className="hover:text-white mx-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedInLog} className="object-cover h-6  lg:h-7 ml-2" alt="Linkedin Logo"/>
          </a>
        </div>
        <FooterItem name={"Contact Us"} link={"/contact"} />
        <FooterItem name={"Products"} link={"/#products"} />
      </div>
      <div className="flex flex-col-reverse items-center lg:justify-center lg:flex-row w-full lg:mt-4">
        {footerLinks.map(({ name, link }) => (
          <FooterItem name={name} link={link} key={name} />
        ))}
      </div>
    </div>
  )
}

const FooterItem = (props: typeof footerLinks[0]) => {
  const item = (
    <div
      className={`text-br-primary-blue text-lg mt-4 lg:mt-0 font-bold lg:mx-4 text-center`}
    >
      {props.name}
    </div>
  )
  if (props.link) {
    return <Link to={props.link}>{item}</Link>
  }
  return item
}
